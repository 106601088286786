import * as React from 'react';
import {useState} from "react";
import {Link} from "gatsby";
import {Helmet} from "react-helmet";

const NavBar = ({current}: { current: string }) => {
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="antialiased bg-gray-100 dark-mode:bg-gray-900">
      <Helmet>
      </Helmet>
      <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
        <div
          className="flex flex-col max-w-screen-xl bg-gray-300 rounded-b-xl lg:rounded-full px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
          <div className="flex flex-row items-center justify-between p-4">
            <Link to="/"
                  className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">
              Mobility Leasing
            </Link>
            <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline" aria-label="Nav Button"
                    onClick={() => setOpen(!open)}>
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                {!open ? <path fillRule="evenodd"
                               d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                               clipRule="evenodd"/>
                  :
                  <path fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"/>
                }
              </svg>
            </button>
          </div>
          <nav className={`flex-col flex-grow ${!open ? "hidden" : "flex"} pb-4 md:pb-0 md:flex md:justify-end md:flex-row`}>
            <Link
              className={`navbar-link ${current == "home" && "border-b-2 border-blue-400"}`}
              to="/">Home</Link>
            <Link
              className={`navbar-link ${current == "service" && "border-b-2 border-blue-400"}`}
              to="/service">Dienstleistungen</Link>
            <Link
              className={`navbar-link ${current == "leasing" && "border-b-2 border-blue-400"}`}
              to="/leasing">Leasing</Link>

            <div className="relative w-auto lg:w-1/4">
              <button onClick={() => setDropdownOpen(!dropdownOpen)}
                className={`dropdown-btn ${current == "contact" && "border-b-2 border-blue-400"}`}>
                <span className="mx-auto">Kontakt</span>
                <svg fill="currentColor" viewBox="0 0 20 20" className={`${dropdownOpen ? "rotate-180" : "rotate-0"} inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1`}>
                  <path fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd" />
                </svg>
              </button>
              { dropdownOpen &&
              <div className="absolute right-0 w-full md:max-w-screen-sm md:w-screen mt-2 origin-top-right">
                <div className="px-2 pt-2 pb-4 bg-white rounded-md shadow-lg dark-mode:bg-gray-700">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Link className="dropdown-link" to="/contact/contact">
                      <div className="bg-blue-300 text-white rounded-lg p-3">
                        <i className="fas fa-map-marker-alt fa-lg"/>
                      </div>
                      <div className="ml-3">
                        <p className="font-semibold">Kontakt</p>
                        <p className="text-sm">So kannst du uns erreichen</p>
                      </div>
                    </Link>
                    <Link className="dropdown-link" to="/contact/request">
                      <div className="bg-blue-300 text-white rounded-lg p-3">
                        <i className="fas fa-envelope fa-lg"/>
                      </div>
                      <div className="ml-3">
                        <p className="font-semibold">Anfrage</p>
                        <p className="text-sm">Hast du eine Frage? Schreib uns</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              }
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavBar;