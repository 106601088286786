import * as React from 'react';
import {Link} from "gatsby";

const Footer = () => {
  return (
      <footer className="items-center justify-between p-3 m-auto absolute bottom-0 w-full h-24 bg-gray-900 mt-8">
        <div className="container mx-auto flex flex-col flex-wrap items-center justify-between">
          <ul className="flex mx-auto text-white text-center">
            <li className="p-2 cursor-pointer hover:underline"><Link to="/impressum">Impressum</Link></li>
            <li className="p-2 cursor-pointer hover:underline"><Link to="/datenschutz">Datenschutz</Link></li>
          </ul>
          <div className="flex mx-auto text-white text-center">
            Copyright Mobility Leasing © 2021
          </div>
        </div>
      </footer>
  );
};

export default Footer;